module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Ilona K: Website copywriter, Content creator, Branding strategist","short_name":"Ilona K","description":"I help businesses and entrepreneurs with website copywriting, content creation, branding strategy while converting qualifying leads into customers.","lang":"en","start_url":"/","background_color":"#EFEFEF","theme_color":"#1F1F1F","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b84f2092a5b5df8011b515ba69683d24"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
